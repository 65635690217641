<template>
  <div>home</div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {}
  },
}
</script>

<style lang="less" module>
.home {
  //
}
</style>
